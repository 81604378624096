/* Bootstrap overrides */
$box-shadow-sm: 0 0 .25rem rgba(#000, .075) !default;
$border-width: 2px;

$black: #000;
$white: #fff;
$grey: #ddd;

body {
  background-color: #eee !important;
}

.pb-title {
  &__brand-logo {
    max-height: 80px;
  }
}

.pb-brand {
  img {
    max-height: 70px;
    width: auto !important;
    margin: 0 auto;
    display: block;
  }
}

.pb-info {
  background: $grey;
  text-transform: uppercase;
}

.pb-wheel-list {
  overflow: auto;
  max-height: 500px;
}

@media (min-width: 768px) {
  .pb-title {
    &__brand-logo {
      max-height: 120px;
    }
  }
}

@import "~bootstrap/scss/bootstrap";
